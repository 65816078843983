import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { json2csv } from 'json-2-csv';
import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { useSuperAdmin } from '../hooks/useSuperAdmin';

interface Membership {
  id: string;
  userId: string;
  organizationId: string;
  role: string;
  joinedAt: Date;
}

interface Organization {
  id: string;
  name: string;
}

interface User {
  id?: string;
  name: string;
  email: string;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const filter = createFilterOptions<User>();

const Memberships: React.FC = () => {
  const { user } = useAuth();
  const [newMember, setNewMember] = useState<User | null>(null);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteMembershipId, setDeleteMembershipId] = useState<string | null>(null);
  const [editUserId, setEditUserId] = useState<string | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editMemberships, setEditMemberships] = useState<Membership[]>([]);
  const [newUserDialogOpen, setNewUserDialogOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState<User | null>(null);
  const [userData, setUserData] = useState<string>();
  const queryClient = useQueryClient();
  const { data: isSuperAdmin } = useSuperAdmin();
  const usernameRef = useRef<HTMLInputElement>();

  useEffect(() => {
    console.log('User:', user);
  }, [user]);

  const { data: memberships, isLoading: isMembershipsLoading } = useQuery({
    queryKey: ['memberships', isSuperAdmin],
    queryFn: async () => {
      console.log('Fetching memberships');
      if (!user?.uid) return [];
      if (isSuperAdmin) {
        const membershipRef = collection(db, 'memberships');
        const allMembershipDocs = await getDocs(membershipRef);
        return allMembershipDocs.docs.map((doc) => ({ ...doc.data(), id: doc.id }) as Membership);
      } else {
        const membershipRef = collection(db, 'memberships');
        const membershipQuery = query(
          membershipRef,
          where('userId', '==', user.uid),
          where('role', '==', 'admin'),
        );
        const membershipDocs = await getDocs(membershipQuery);
        console.log('Admin memberships:', membershipDocs.docs.length);
        const adminOrgIds = membershipDocs.docs.map((doc) => doc.data().organizationId);

        const allMembershipsQuery = query(
          membershipRef,
          where('organizationId', 'in', adminOrgIds),
        );
        const allMembershipDocs = await getDocs(allMembershipsQuery);
        console.log('All memberships:', allMembershipDocs.docs.length);

        return allMembershipDocs.docs.map((doc) => ({ ...doc.data(), id: doc.id }) as Membership);
      }
    },
    enabled: !!user?.uid,
  });

  const { data: organizations, isLoading: isOrgsLoading } = useQuery({
    queryKey: ['organizations', isSuperAdmin],
    queryFn: async () => {
      console.log('Fetching organizations');
      if (!user?.uid) return [];
      if (isSuperAdmin) {
        // Fetch all organizations for super admin
        const orgRef = collection(db, 'organizations');
        const orgDocs = await getDocs(orgRef);
        return orgDocs.docs.map((doc) => ({ ...doc.data(), id: doc.id }) as Organization);
      } else {
        const membershipRef = collection(db, 'memberships');
        const membershipQuery = query(
          membershipRef,
          where('userId', '==', user.uid),
          where('role', '==', 'admin'),
        );
        const membershipDocs = await getDocs(membershipQuery);

        const orgIds = membershipDocs.docs.map((doc) => doc.data().organizationId);

        const orgRef = collection(db, 'organizations');
        const orgQuery = query(orgRef, where('__name__', 'in', orgIds));
        const orgDocs = await getDocs(orgQuery);
        console.log('Organizations:', orgDocs.docs.length);

        return orgDocs.docs.map((doc) => ({ ...doc.data(), id: doc.id }) as Organization);
      }
    },
    enabled: !!user?.uid,
  });

  const { data: allUsers, isLoading: isAllUsersLoading } = useQuery({
    queryKey: ['allUsers'],
    queryFn: async () => {
      const usersRef = collection(db, 'users');
      const usersDocs = await getDocs(usersRef);
      return usersDocs.docs.map((doc) => ({ ...doc.data(), id: doc.id }) as User);
    },
    enabled: !!user?.uid,
  });

  useEffect(() => {
    if (!isMembershipsLoading && !isAllUsersLoading) {
      buildUserDataString();
    }
  }, [isMembershipsLoading, isAllUsersLoading]);

  const addUser = useMutation({
    mutationFn: async (user: User) => {
      if (user.id != null) {
        throw new Error('Attempt to add an existing user.');
      }
      user = { ...user, email: user.email.toLowerCase() };
      const { id } = await addDoc(collection(db, 'users'), {
        email: user.email,
        name: user.name,
      });
      return { ...user, id };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['allUsers'] });
    },
  });

  const buildUserDataString = async () => {
    const userData = allUsers?.map((user: User) => {
      if (user.id) {
        const userMemberships = getUserMemberships(user.id);
        return {
          ...user,
          orgMembershipsName: userMemberships.map((mem) => mem.organizationId),
          orgMembershipsId: userMemberships.map((mem) => mem.id),
        };
      } else return {};
    });

    const objArr = JSON.parse(JSON.stringify(userData));
    const csv = json2csv(objArr, { excludeKeys: ['email_original', 'userId'] });

    setUserData(csv);
  };

  const updateUsername = useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const newName = usernameRef.current?.value || userToEdit?.name;
      await updateDoc(doc(db, 'users', id), { ...userToEdit, name: newName });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['allUsers'] });
      setUserToEdit(null);
    },
  });

  const getUserMemberships = (userId: string) =>
    memberships?.filter((m) => m.userId === userId) || [];

  const addMembership = useMutation({
    mutationFn: async ({
      userId,
      orgId,
      role,
    }: {
      userId: string;
      orgId: string;
      role: string;
    }) => {
      await addDoc(collection(db, 'memberships'), {
        userId: userId,
        organizationId: orgId,
        role: role,
        joinedAt: new Date(),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['memberships'] });
    },
  });

  const updateMembership = useMutation({
    mutationFn: async ({ id, role }: { id: string; role: string }) => {
      await updateDoc(doc(db, 'memberships', id), { role });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['memberships'] });
    },
  });

  const deleteMembership = useMutation({
    mutationFn: async (id: string) => {
      await deleteDoc(doc(db, 'memberships', id));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['memberships'] });
      setDeleteMembershipId(null);
      setOpenDialog(false);
    },
  });

  const handleAddMembership = () => {
    if (newMember && selectedOrg) {
      if (newMember.id) {
        // This is an existing user. Just add the membership.
        addMembership.mutate({ userId: newMember.id, orgId: selectedOrg, role: 'member' });
        setNewMember(null);
        setSelectedOrg('');
      } else {
        // This user doesn't exist. Create the user then add the membership.
        alert('User not found');
      }
    }
  };

  const handleEditUserRole = (userId: string) => {
    const userMemberships = getUserMemberships(userId);
    setEditUserId(userId);
    setEditMemberships(userMemberships);
    setEditDialogOpen(true);
  };

  const handleEditUsername = (user: User) => {
    if (user.id) {
      setUserToEdit(user);
    }
  };

  const handleUserCreate = async (user: User) => {
    console.log(user);
    setNewUserDialogOpen(false);

    const newUser = await addUser.mutateAsync(user);

    setNewMember(newUser);
  };

  const handleUpdateMembership = (membershipId: string, role: string) => {
    updateMembership.mutate({ id: membershipId, role });
  };

  const handleDeleteMembership = (id: string) => {
    setDeleteMembershipId(id);
    setOpenDialog(true);
  };

  const confirmDelete = () => {
    if (deleteMembershipId) {
      deleteMembership.mutate(deleteMembershipId);
    }
  };

  const handleAddNewMembership = () => {
    if (editUserId && selectedOrg) {
      addMembership.mutate({ userId: editUserId, orgId: selectedOrg, role: 'member' });
      setSelectedOrg('');
    }
  };

  if (isMembershipsLoading || isOrgsLoading || isAllUsersLoading) {
    return (
      <Container maxWidth="md">
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Memberships
        </Typography>
        <StyledPaper>
          <Typography variant="h6" gutterBottom>
            Add New Member
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Autocomplete
                fullWidth
                options={allUsers || []}
                getOptionLabel={(option) => {
                  // for example value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  } else {
                    return option.email;
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    type="email"
                    label="New Member Email"
                    variant="outlined"
                    error={emailError}
                  />
                )}
                value={newMember}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    // timeout to avoid instant validation of the dialog's form.
                    setTimeout(() => {
                      setNewMember({
                        email: newValue,
                        name: '',
                      });
                      setNewUserDialogOpen(true);
                    });
                  } else if (newValue && !newValue.id) {
                    setNewMember(newValue);
                    setNewUserDialogOpen(true);
                  } else {
                    setNewMember(newValue);
                  }
                }}
                renderOption={(props, option) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      {option.id ? option.email : `Add "${option.email}"`}
                    </li>
                  );
                }}
                freeSolo
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  if (
                    params.inputValue !== '' &&
                    !filtered.find((entry) => entry.email === params.inputValue)
                  ) {
                    filtered.push({
                      name: '',
                      email: params.inputValue,
                    });
                  }

                  return filtered;
                }}
                clearOnBlur
                selectOnFocus
                isOptionEqualToValue={(option, value) => option.email === value.email}
                onInputChange={(event, value) => {
                  const target = event?.target as HTMLInputElement;
                  target && setEmailError(!target.validity?.valid);
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Select Organization</InputLabel>
                <Select
                  value={selectedOrg}
                  onChange={(e) => setSelectedOrg(e.target.value as string)}
                  label="Select Organization"
                >
                  {organizations?.map((org) => (
                    <MenuItem key={org.id} value={org.id}>
                      {org.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleAddMembership}
                disabled={!newMember || !selectedOrg}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </StyledPaper>
        <StyledPaper>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" gutterBottom>
              Users
            </Typography>
            {userData && (
              <a href={`data:application/octet-stream,${encodeURI(userData)}`} download="users.csv">
                <Button variant="contained">Export</Button>
              </a>
            )}
          </Box>
          <List>
            {allUsers?.map((user) => {
              const userMemberships = memberships?.filter((m) => m.userId === user.id) || [];
              return (
                <ListItem key={user.id} divider>
                  <ListItemText
                    primary={
                      (
                        <>
                          {user.name}
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            size="small"
                            onClick={() => user.id != null && handleEditUsername(user)}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </>
                      ) || `Unknown User (ID: ${user.id})`
                    }
                    secondary={
                      <React.Fragment>
                        <span>{user.email}</span>
                      </React.Fragment>
                    }
                  />
                  {userMemberships.length > 0
                    ? userMemberships.map((m) => {
                        const org = organizations?.find((o) => o.id === m.organizationId);
                        return (
                          <Chip
                            component={'span'}
                            key={m.id}
                            label={`${org?.name || 'Unknown Org'} (${m.role})`}
                            size="small"
                            sx={{ mr: 1, mb: 1 }}
                          />
                        );
                      })
                    : 'No memberships'}
                  <Tooltip title="Edit User Memberships">
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => user.id != null && handleEditUserRole(user.id)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </ListItem>
              );
            })}
          </List>
        </StyledPaper>
      </Box>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this membership? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit User Memberships</DialogTitle>
        <DialogContent>
          <List>
            {editMemberships.map((membership) => {
              const org = organizations?.find((o) => o.id === membership.organizationId);
              return (
                <ListItem key={membership.id}>
                  <ListItemText primary={org?.name || 'Unknown Organization'} />
                  <FormControl variant="outlined" sx={{ minWidth: 120, mr: 2 }}>
                    <InputLabel>Role</InputLabel>
                    <Select
                      value={membership.role}
                      onChange={(e) =>
                        handleUpdateMembership(membership.id, e.target.value as string)
                      }
                      label="Role"
                    >
                      <MenuItem value="admin">Admin</MenuItem>
                      <MenuItem value="manager">Manager</MenuItem>
                      <MenuItem value="member">Member</MenuItem>
                    </Select>
                  </FormControl>
                  <IconButton onClick={() => handleDeleteMembership(membership.id)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              );
            })}
          </List>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Add New Membership
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Select
                  fullWidth
                  value={selectedOrg}
                  onChange={(e) => setSelectedOrg(e.target.value as string)}
                  variant="outlined"
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select Organization
                  </MenuItem>
                  {organizations?.map((org) => (
                    <MenuItem key={org.id} value={org.id}>
                      {org.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleAddNewMembership}
                  disabled={!selectedOrg}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* // TODO: THIS IS THE ONE */}
      <Dialog open={!!userToEdit} onClose={() => setUserToEdit(null)} maxWidth="md" fullWidth>
        <DialogTitle>Edit Username</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Username
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Input
                  inputRef={usernameRef}
                  fullWidth
                  name="editUsername"
                  defaultValue={userToEdit?.name}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    updateUsername.mutate({ id: userToEdit?.id || '' });
                  }}
                  disabled={!userToEdit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUserToEdit(null)}>Close</Button>
        </DialogActions>
      </Dialog>
      <NewUserDialog
        open={newUserDialogOpen}
        user={newMember ?? { name: '', email: '' }}
        onClose={() => setNewUserDialogOpen(false)}
        onCreate={handleUserCreate}
      />
    </Container>
  );
};

const NewUserDialog = ({
  open,
  user: initialUser,
  onCreate,
  onClose,
}: {
  open: boolean;
  user: User;
  onCreate: (user: User) => unknown;
  onClose: () => unknown;
}) => {
  const [user, setUser] = useState<User>(initialUser);

  useEffect(() => {
    if (open) {
      setUser(initialUser);
    }
  }, [initialUser, open]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onCreate(user);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Create new user</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            fullWidth
            id="email"
            value={user.email}
            onChange={(event) =>
              setUser({
                ...user,
                email: event.target.value,
              })
            }
            required
            label="email"
            type="email"
            variant="standard"
          />
          <TextField
            margin="dense"
            fullWidth
            id="name"
            value={user.name}
            onChange={(event) =>
              setUser({
                ...user,
                name: event.target.value,
              })
            }
            required
            label="name"
            type="text"
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Memberships;
